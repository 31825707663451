import React from "react";
import * as Icon from "react-feather";
import {ROLES} from "./roles"

const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,     
    projectEnable: false,
    navLink: "/",
  },
  {
    id: "videoList",
    title: "Videos",
    type: "item",
    icon: <Icon.List size={20} />,
    projectEnable: true,
    navLink: "/org/video",
  },
  // {
  //   id: "videoListV2",
  //   title: "Videos V2",
  //   type: "item",
  //   icon: <Icon.List size={20} />,
  //   projectEnable: true,
  //   navLink: "/org/video2",
  // },
  {
    id: "videoNew",
    title: "New Video",
    type: "item",
    icon: <Icon.PlusSquare size={20} />,
    projectEnable: true,
    navLink: "/org/video/new",
  },
  {
    id: "userList",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: [ROLES.admin, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/user",
  },
  {
    id: "organizationList",
    title: "Organizations",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: [ROLES.admin, ROLES.superAdmin],
    projectEnable: true,
    navLink: "/org/organization",
  },
];

export default navigationConfig;
