import { createSlice } from "@reduxjs/toolkit";

// State can be "mutated" because of Immer (part of Redux Toolkit) https://github.com/immerjs/immer
export const TranscriptionSlice = createSlice({
  name: "transcription",
  initialState: {
    pathVideo:null,
    pathVideoSub:null,
    jsonTranscription:null,
    videoInfo: null,
    transcriptionInfo: null,
  },
  reducers: {
    setData: (state, action) => {
      state.pathVideo = action.payload.pathVideo;
      state.pathVideoSub = action.payload.pathVideoSub;
      state.jsonTranscription = action.payload.jsonTranscription;
      state.videoInfo = action.payload.videoInfo;
      state.transcriptionInfo = action.payload.transcriptionInfo;
    },
    setJsonTranscription: (state, action) => {
      state.jsonTranscription = action.payload;
    },
    clear: (state) => {
      state.pathVideo = null;
      state.jsonTranscription = null;
      state.pathVideoSub = null;
    },
  },
});
