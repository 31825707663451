export const COMPONENTS = {
  LOGIN: "LOGIN",
  NEW_VIDEO: "NEW_VIDEO",
  LIST_VIDEO: "LIST_VIDEO",
  NEW_TRANSCRIPTION: "NEW_TRANSCRIPTION",
  LIST_TRANSCRIPTIONS: "LIST_TRANSCRIPTIONS",
  DELETE_TRANSCRIPTION: "DELETE_TRANSCRIPTION",
  LOCK_TRANSCRIPTION: "LOCK_TRANSCRIPTION",
  DELETE_VIDEO: "DELETE_VIDEO",
  GET_TRANSCRIPTIONS: "GET_TRANSCRIPTIONS",
  SUBTITLE_COMPONENT:"SUBTITLE_COMPONENT",
  NEW_USER: "NEW_USER",
  DELETE_USER: "DELETE_USER",
  TEST_TTS: "TEST_TTS",
  CHANGE_TRANSLATOR:"CHANGE_TRANSLATOR"
};
