import { batch } from "react-redux";
import { COMPONENTS } from "../../../configs/components";
import { asyncHelperActions } from "../ayncHelper/asyncHelperSlice";
import { MyAccountSlice } from "./slice";
import { AXIOS_INSTANCE, ROUTES, URL_BACKEND } from "../../../configs/routing";

export const fetchMyAccount = (userUID) => {
  return (dispatch) => {
    dispatch(
      asyncHelperActions.setFetching({ componentName: COMPONENTS.MY_ACCOUNT })
    );
    return AXIOS_INSTANCE.get(URL_BACKEND + ROUTES.USER + "/" + userUID)
      .then((response) => {
        const { data } = response;
        batch(() => {
          dispatch(MyAccountSlice.actions.setAccount({ account: data.user }));
          dispatch(
            asyncHelperActions.setSuccess({
              componentName: COMPONENTS.MY_ACCOUNT,
            })
          );
        });
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          e.message = "The server could not be reached";
        } else if (e.response.status === 401) {
          e.message = "Invalid Permissions";
        }

        dispatch(
          asyncHelperActions.setError({
            componentName: COMPONENTS.MY_ACCOUNT,
            message: e.message,
          })
        );
        throw e;
      });
  };
};

export const postAccountChanges = (new_account) => {
  return (dispatch) => {
    dispatch(
      asyncHelperActions.setPosting({ componentName: COMPONENTS.MY_ACCOUNT })
    );
    return AXIOS_INSTANCE.put(URL_BACKEND + ROUTES.USER ,new_account)
      .then((response) => {
        const {data} = response
        batch(() => {
          dispatch(MyAccountSlice.actions.setAccount({ account: data.user }));
        });
      }).catch((e)=>{
        // console.log(e)
      });
  };
};
