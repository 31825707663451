import { createSlice } from "@reduxjs/toolkit";

// State can be "mutated" because of Immer (part of Redux Toolkit) https://github.com/immerjs/immer
export const LastVoiceSlice = createSlice({
  name: "lastVoice",
  initialState: {
    lastVoice: null,
  },
  reducers: {
    clearLastVoice: (state) => {
      state.lastVoice = null;
    },
    setData: (state, action) => {
      state.lastVoice = action.payload;
    },
  },
});
