import Axios from "axios";

export const BACKEND = process.env.REACT_APP_BACKEND;
export const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT;
export const BACKEND_WS = process.env.REACT_APP_BACKEND_WS;
export const VOICE_BUCKET_URL = process.env.REACT_APP_VOICE_BUCKET_URL;
export const URL_BACKEND = BACKEND + ":" + BACKEND_PORT + "/";
export const URL_BACKEND_WS = BACKEND_WS + ":" + BACKEND_PORT + "/";

export const ROUTES = {
  LOGIN: "login",
  PUBLIC: "public",
  TRANSCRIPTIONS: "transcriptions",
  LOCK: "lock",
  VIDEOS:"videos",
  INDEX:"index",
  UPDATE:"update",
  FILES:"files",
  NEW:"new",
  NEW_URL: "new_url",
  DURATION_URL: "duration_url",
  TTS:"tts",
  UPDATE_VIDEO: "videos/update",
  VALID_TOKEN: "valid_token",
  USER: "user",
  LIST: "list",
  TTS: "tts",
  PREVIEW: "preview",
  REGENERATE_TTS: "regenerate_tts",
  REGENERATE_TTS_SYNC: "regenerate_tts_sync",
  ORGANIZATION:"organizations",
  EXPORT:"export",
  EMBED:"embed",
  PRESIGNED_URL: "presigned_url",
  VIDEO_SUBTITLE_PRESIGNED_URL: "video_subtitle_presigned_url",
  BUYERS: "buyers",
  DOWNLOAD: "download",
  BUYERS_CHECK: "check_buyer_credits"
};

export const AXIOS_INSTANCE = Axios.create({
  baseURL: URL_BACKEND,
});
